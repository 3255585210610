<script setup lang="ts">
const { t } = useI18n()

defineRouteRules({
  prerender: true,
});

</script>

<template>
  <div class="c40 p-4">
    <h1>{{ t('general.not-found') }}</h1>
    <p class="mt-4">
      Go to <router-link to="/">Home</router-link>
    </p>
  </div>
</template>

<style scoped>
.c404 {
  height: 550px;
  @apply w-full;
}
</style>